import { Link } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";

import testingImg from "../images/testing-image.jpg";

import apiDesktop from '../images/src/pages/api/api_1_desktop.jpg'
import apiDesktopR from '../images/src/pages/api/api_1_desktop@2x.jpg'

import apiMobile from '../images/src/pages/api/api_1_mobile.jpg'
import apiMobileR from '../images/src/pages/api/api_1_mobile@2x.jpg'

import "../styles/api/index.scss";

class APIBatisseur extends React.Component {
  render() {
    return (
      <>
      <Helmet>
        <title>API Batisseurs | Menuiserie Perret</title>
      </Helmet>
        <section className="api__header">
          <Link to="/nos-valeurs">
            ← nos valeurs
          </Link>
          <h1 className="big-title">Nous sommes API Bâtisseurs</h1>
          <picture>
            <source media="(min-width: 770px)" srcSet={apiDesktopR + ' 2x, ' + apiDesktop + ' 1x'}/>
            <img src={apiMobile} srcSet={apiMobileR + ' 2x, ' + apiMobile + ' 1x'} alt="Image de test"></img>
          </picture>
          <p>
            Dans le cadre de son mécénat environnemental en partenariat avec le
            Grand Parc Miribel Jonage, un collectif de huit PME du BTP du
            Rhône-Alpes, a invité ses partenaires à venir échanger sur leurs
            enjeux environnementaux.
          </p>
        </section>
        <section className="api__content">
          <p>
            C'est à l'initiative et avec le soutien de l'organisme de formation
            en Responsabilité Sociétale des Entreprises (RSE), Acta Qualité, que
            huit PME du BTP du Rhône-Alpes (**BOURDIN, L'ELECTRICITE, ENTREPRISE
            PASCAL, PERRET SAS, REGUILLON & Cie, REPPELIN, RHÔNE TOITURES et
            SIFFERT**) ont choisi de réunir leurs partenaires pour une
            demi-journée d'échanges sur leurs enjeux environnementaux
            respectifs.
          </p>
          <p>
            C'est dans la logique de leur engagement sociétal développé
            notamment dans le cadre de leur participation au programme de
            formation « Bonnes pratiques du développement durable dans le BTP® »
            de l'organisme de formation Acta Qualité et dans la reconnaissance
            de leur rôle dans la dynamique économique et environnementale
            locale, que ces huit PME ont souhaité être les mécènes du Grand Parc
            Miribel Jonage et ainsi soutenir ses activités agroforestières et
            apicoles.
          </p>
          <p>
            Ce mécénat s'inscrit ainsi dans une démarche de promotion et de mise
            en œuvre d'une approche plus responsable dans la pratique de leur
            métier au quotidien. Magali Géry, gérante d'Acta Qualité témoigne :
            « A travers cet engagement symbolique, ces entreprises n'affirment
            pas seulement leurs responsabilités sociétales, c'est également pour
            elles un appel au partage et à la collaboration avec leurs
            partenaires et clients. Ce sont là en effet, les conditions
            indispensables à la pérennisation de leurs actions au quotidien ».
          </p>
        </section>
      </>
    );
  }
}

export default APIBatisseur;
